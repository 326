.row {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 30px;
    margin-bottom: 3%;
    width: 80%;
    margin: auto;
}

.service {
    text-align: center;
    padding: 5% 3%;
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
    background: transparent;
    transition: transform 0.5s, background 0.5s;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    margin-bottom: 5%;
}

.service-icons{
    margin-bottom: 10px;
    
}
.main-section h1{
    text-align: center;
    padding-bottom: 1%;
    margin-bottom: 5%;
    position: relative;
}
.main-section h1::after{
    content: "";
    background: var(--color-secondary);
    width: 100px;
    height: 5px;
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);  
}
.service h2 {
    font-weight: 600;
    margin-bottom: 8px;
    color: var(--color-dark);
}

.service:hover {
    
    transform: scale(1.05);
}

