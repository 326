#about{
    background-color: #fff;
    padding: 10% 0;
}
.about-container{
    margin: auto;
    width: 80%;
    justify-content: space-between;
    display: flex;
    gap: 20px;
}
#about h1{
    text-align: center;
    padding-bottom: 1%;
    margin-bottom: 5%;
    position: relative;
}
#about h1::after{
    content: "";
    background: var(--color-secondary);
    width: 100px;
    height: 5px;
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
}
.about-container > div {
    flex: 1;
}
.about-container p {
    line-height: 1.8em;
}
#about-contents{
    margin-bottom: 3%;
}

@media screen and (max-width: 768px){
    
    .about-container{
        display: flex;
        flex-direction: column;
         justify-content: center;
         align-items: center;
    }
    .about-container > div {
        flex: 1 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}