#contact{
    background-color: #fff;
    width: 100%;
      height: 100vh;
      display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.contact-card{
    margin: auto;
    width: 80%;
    
}

.flex-inputs{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10%;
    width: 100%;
    margin: 3% 0;
}
#input::placeholder{
    font-size: 16px;
}
span.p{
    text-align: center;
    font-size: 16px;
    margin-top: 2%;
    line-height: 1.8em;
}
form .h1{
    text-align: center;
    padding-bottom: 1%;
    margin-bottom: 3%;
    position: relative;
    font-size: 2.5rem;
    font-weight: bold;
}
.h1::after{
    content: "";
    background: var(--color-secondary);
    width: 100px;
    height: 5px;
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);  
}

@media screen and (max-width: 768px){
    .flex-inputs{
        display: flex;
        flex-direction: column;
        margin: 0;
    }
    span.p{
        
        font-size: 12px;
       
    }
    form .h1{
        
        font-size: 16px;
        
    }
}