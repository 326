.main{
    margin: auto;
}
.home-container{
   
    display: flex;
    justify-content: space-around;
    /* align-items: center; */
}

@media screen and (max-width: 768px){
    .home-container{
        
        display: flex;
        flex-direction: column;
        gap: 30px;
       
    }
    .vision-mission,
    .events{
        width: 100%;
    }
}