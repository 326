.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
  }
  
  .not-found {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 50vh;
    width: 50vw;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }
  
  .not-found h1 {
    font-size: 5rem;
    font-weight: 700;
    color: #333;
  }
  
  .not-found h2 {
    font-size: 2rem;
    font-weight: 500;
    color: #333;
  }
  
  .not-found p {
    font-size: 1.5rem;
    font-weight: 500;
    color: #333;
  }