.change-password {
    .card {
      width: 100%;
      max-width: 400px;
      padding: 1rem;
      border: 1px solid red;
    }
    
  
      h3 {
        color: #fff;
        font-size: 2rem;
        text-align: center;
      }
    
    form {
      
      label {
        display: block;
        font-size: 1.4rem;
        font-weight: 500;
      }
      input[type="text"],
      input[type="number"],
      input[type="file"],
      input[type="email"],
      select,
      textarea,
      input[type="password"] {
        display: block;
        font-size: 1.6rem;
        font-weight: 300;
        padding: 1rem;
        margin: 1rem auto;
        width: 100%;
        border-radius: 3px;
        outline: none;
      }
      
    }

  .group {
    border: 1px solid var(--light-blue);
    padding: 5px;
    margin-bottom: 5px;
    width: 100%;
  }
  .indicator {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 12px;
  }
  
}
    
  