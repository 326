.blogsCard {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    margin: 3% 0;
}
.blog {
    width: 90%;
    margin: auto;
    border-bottom: 1px solid #f5f5f5;
    padding: 3% 0;
}
.content 
    {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 800px;
      }

.blog-link {
    float: right;
}

.blog-link p {
    color: var(----color-primary);
}

