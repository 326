.topSection{
    background-color: #1f2022;
    padding: 3% 0;
    margin-bottom: 2%;
    text-align: center;
}
.topSection h1{
    font-size: 2em;
    font-weight: bold;
    color: white;
    margin: auto;
    width: 80%;
    
}