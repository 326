.deposit{
    display: flex;
    justify-content: space-between;
    .addUserBtn{
      #addIcon{
        margin-right: 5px;
      }
      
    }
  }
  
  .deposit-list {
    color: #333;
    margin: 0;
      padding: 3% 5%;
      width: 100%;
      overflow-y: scroll;
      height: 80vh;
    .table {
      padding: 5px;
      width: 100%;
      overflow-x: auto;
  
      .search {
        width: 100%;
        max-width: 300px;
      }
  
      table {
        border-collapse: collapse;
        width: 100%;
        font-size: 1.4rem;
        
      
  
        thead {
         
          border: 2px solid var(--color-secondary);
          background-color: var(--color-secondary);
          color: #fff;
        }
  
        th {
          border: 1px solid #eee;
        }
  
        th,
        td {
          vertical-align: top;
          text-align: left;
          padding: 8px;
  
          &.icons {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            > * {
              margin-right: 7px;
              cursor: pointer;
              vertical-align: middle;
              align-self: center;
            }
          }
          .react-datepicker-wrapper{
            .react-datepicker__input-container {
                input{
                    padding: 3%;
                    border: none;
                    cursor: pointer;
                    border: none;
                }
            }
        }
        }
  
        tr {
          border-bottom: 1px solid #ccc;
        }
  
        tr:nth-child(even) {
          background-color: #fff;
        }
        tbody {
          tr:hover {
            // cursor: pointer;
            background-color: rgba(121, 136, 149, 0.3);
          }
        }
      }
    }
  }
  
  .sort {
    label {
      font-size: 1.4rem;
      font-weight: 500;
      margin: 0 5px;
    }
    select {
      font-size: 1.6rem;
      font-weight: 300;
      padding: 4px 8px;
      margin: 0 5px 0 0;
      border: none;
      border-bottom: 2px solid #777;
      outline: none;
    }

    
}

.btn-remind-all {
  padding: 1px 4px !important;
  line-height: 1.9rem;
  font-size: 12px;

}