.loan-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 10px;
}

.loan-label {
  font-size: 20px;
  font-weight: 600;
}

.loan-label.title {
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 20px;
}

.loan-input {
  width: 100%;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #ccc;
  padding: 10px;
  margin: 10px 10px 10px 0;
}

.loan-input:focus {
  outline: none;
  border: 1px solid #000;
}

.user-agreement-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  margin-top: 20px;
}

.user-agreement-form-title {
  margin-bottom: 10px;
}

.user-agreement-form-content {
  font-weight: 400;
  margin-bottom: 10px;
  overflow-y: scroll;
}

.user-agreement-form-checkbox {
  margin: 10px 0;
}

.user-agreement-form-checkbox label {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  font-size: 12px;
}

.user-agreement-form-checkbox input {
  margin-right: 10px;
}

.loan-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  margin-top: 20px;
}

.loan-error-message {
  color: red;
  font-size: 12px;
  margin: 5px 0;
}

.loan-submit-button {
  height: 40px;
  border-radius: 5px;
  border: none;
  background-color: orange;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  margin-top: 20px;
  cursor: pointer;
  padding: 0 20px;
}

.loan-submit-button:hover {
  background-color: #333;
}

.loan-label-content {
  display: flex;
  align-items: center;
}

.loan-edit-button {
  height: 40px;
  border-radius: 5px;
  border: none;
  background-color: orange;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  padding: 0 20px;
}

.disbursment-form {
  padding: 40px 50px;
  background-color: #b4b4b4;
  color: white;
  border-radius: 20px;
}
/* .disbursment-form h1,  .disbursment-form h3{
  color: white;
} */
#display-loan-admin-container {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 10px;
  border-bottom: 1px solid gray;
}

.loan-display-table {
  border: 1px solid black;
  margin: 5px;
  border-collapse: collapse;
  width: 100%;
  font-size: 14px;
}
.loan-display-table td {
  padding: 10px 20px;
  border: 1px solid #ddd;
}

.loan-display-table tr:nth-child(even){background-color: #f2f2f2;}
.loan-display-table tr td:first-child{
  font-weight: bold;
}
.loan-display-table tr:hover {background-color: #ddd;}

.loan-display-table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #04AA6D;
  color: white;
}


#display-loan-admin-container h2 {
  text-align: center;
  color: black;
}

#display-loan-admin-container button {
  float: right;
}
