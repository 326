.card{
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 60%;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 18px 50px -10px;
    padding: 5%;
    border-radius: 10px;
    background-color: white;
    // margin: 2%;
}
