.eventsCard {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    margin: 3% 0;
}
.event {
    width: 90%;
    margin: auto;
    border-bottom: 1px solid #f5f5f5;
    padding: 3% 0;
}

#date-time {
    display: flex;
    justify-content: space-between;
}
#date-time > div {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}
