.headerHero{
    height: 80vh;
    background-image: linear-gradient(to right, var(--color-secondary ), #89CFF0);
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 10%;
    overflow: hidden;
    
}
 
.header-info{
display: flex;
flex-direction: column;
justify-content: space-around;
gap: 30px;
}
#logo img{
    width: 80%;
    height: 40%;
}

.header-info h1{
   
    font-size: 5rem;
    
}
.header-info h2{
    font-size: 4rem;
    
    color: var(--color-dark);
    
}

.button{
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 16px;
    cursor: pointer;
}

@media screen and (max-width: 768px){
    .headerHero{
        height: 50vh;
    }
    .header-info h1{
        font-size: 3rem;
    }
    .header-info h2{
        font-size: 2rem;
    }
    .button{
        
        font-size: 12px;
    }
    
    
}

@media screen and (max-width: 600px){
    #logo img{
        display: none;
    }
    #logo {
        display: none;
    }
    .header-info{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
    }
}