.alert {
    width: 100%;
    border: 1px solid rgb(232, 72, 72);
    border-radius: 3px;
    background-color: rgba(222, 31, 31, 0.2);
    position: relative;
    display: flex;
    justify-content: flex-start;
    padding: 1rem;
  }
  
  .v-link {
    cursor: pointer;
    color: var(--light-blue);
  }
  
  .alert p {
    font-size: 1.3rem;
  }
  @media screen and (max-width: 600px) {
    .alert p {
      font-size: 1.2rem;
    }
  }