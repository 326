/* Blog.css */
.blog {
    border: 1px solid #e0e0e0;
    background-color: #fff;
    padding: 30px;
    /* margin: 20px; */
    max-width: 600px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    margin: 10px auto;
    max-width: 1200px;
  }
  
  .blog-title {
    font-size: 2rem;
    line-height: 3rem;
    color: #333;
    margin-bottom: 10px;
  }
  
  .blog-image {
    max-width: 100%;
    max-height: 550px; /* Adjust the maximum height as needed */
    width: 100%;
    height: auto;
    margin-bottom: 15px;
    border-radius: 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    object-fit: cover;
  }
  
  /* .blog-author,
  .blog-published-date {
    color: #666;
    font-size: 1.1rem;
    margin-bottom: 5px;
  } */

  .blog-meta {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.9rem;
    color: #666;
    margin-bottom: 5px;
  }
  
  .blog-content {
    margin-top: 15px;
    color: #333;
    line-height: 1.3;
    font-size: 1.7rem;
    white-space: pre-wrap;
  }
  