.profile {
    .card {
      width: 100%;
      
      padding: 1rem;
      border: 1px solid #030b6b;
    }
    .profile-photo {
      width: 100%;
      // border: 1px solid #fff;
      background-color: var(--light-blue);
      padding: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 1rem;
  
      & img {
        width: 100px;
        height: 20%;
        border: 2px solid #fff;
        border-radius: 50%;
      }
  
      & h3 {
        color: #fff;
        font-size: 2rem;
        text-align: center;
      }
    }
    form {
      label {
        display: block;
        font-size: 1.4rem;
        font-weight: 500;
      }
      input[type="text"],
      input[type="number"],
      input[type="file"],
      input[type="email"],
      select,
      textarea,
      input[type="password"] {
        display: block;
        font-size: 1.6rem;
        font-weight: 300;
        padding: 1rem;
        margin: 1rem auto;
        width: 100%;
        
        border-radius: 3px;
        outline: none;
      }
  
      // textarea {
      //   width: 100%;
      // }
    }
  }
  .greeting{
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
    align-items: center;
  }

  
  
  
  // Break points map 
  $breakpoints: (
    "xs": "max-width: 575.98px",
    "sm": "(min-width: 576px) and (max-width: 767.98px)",
    "md": "(min-width: 768px) and (max-width: 991.98px)",
  );

  @mixin breakpoint($user-value) {
    $my-map: map-get($breakpoints, $user-value);
    @if $my-map {
      @media (#{my-map}) {
        @content;
      }
    } @else {
      @error "Oopps, seems like the breakpoint #{$user-value} isn't defined yet"
    }
  };

profile {
  .card{
    .profile-photo{
      & img {
        @include breakpoint(xs){
          width: 100px;
          height: 100px;
        }
      }
    }
  }
}
  

