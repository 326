.sidebar {
  height: 100vh;
  background-color: rgb(251, 251, 255);
  position: sticky;
  /* top: 50px; */
  width: 20%;
}

.sidebarWrapper {
  padding: 20px;
  color: #555;
}

.sidebarMenu {
  margin-bottom: 10px;
}

.sidebarTitle {
  font-size: 13px;
  color: rgb(187, 186, 186);
}

.sidebarList {
  list-style: none;
  padding: 5px;
}

.sidebarListItem {
  padding: 5%;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 10px;
  font-size: 16px;
}

.sidebarListItem:hover {
  background-color: rgb(240, 240, 255);
}

.sidebarIcon {
  margin-right: 5px;
  font-size: 20px !important;
}

@media screen and (max-width: 768px) {
  .sidebar {
    width: 35%;
  }
  .sidebarListItem {
    font-size: 12px;
  }
}
