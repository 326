.contact-container{
    margin: auto;
    width: 80%;
    display: flex;
    flex-direction: row;
    padding: 5% 0;
    justify-content: center;
    align-items: center;
}
.info{
    background-color: #2957A4;
    padding: 3%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;

}
.info h1{

    font-size: 1.5em;
    font-weight: bold;
    color: white;
    line-height: 2.5em;

}
.info .contact{
    color: white;
}
.info .sm_icons{
    color: white;
    
}
.info .sm_icons i{
    font-size: 2em;
    
}

form.h1{
    font-size: 3rem;
    font-weight: bold;
}


.MuiSelect-select{
    color: #a6a6a6;
    font-size: 14px;
}
.sort{
    width: 100%;
}


