  #investment{
      width: 100%;
      
      background-position: center;
      background-size: cover;
      background-image: url("../../assets/investmentimg.jpg");

  }
  .investment-overlay{
      width: 100%;
      background-color: rgba(255,255,255,0.9);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; 
      padding: 10%;
      
  }
  .investment-overlay h1{
      
      text-align: center;
        padding-bottom: 1%;
        margin-bottom: 7%;
        position: relative;

  }
  .investment-overlay h1::after{
    content: "";
    background: var(--color-secondary);
    width: 100px;
    height: 5px;
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);  
}
  .investment-overlay p{
    
    font-size: 18px;
    line-height: 3.8rem;
    text-align: center;  
}

@media screen and (max-width: 768px) {
  .investment-overlay p{
    
    font-size: 12px;
    line-height: 3.8rem;
    text-align: center;  
}

}